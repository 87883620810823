import {
  Box,
  Flex,
  TabList,
  Tabs,
  Tab,
  TabPanels,
  TabPanel,
  Tooltip,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import ParagraphTitle from '../Paragraph/ParagraphTitle';
import useIsMobile from '../../hooks/useIsMobile';
import { forceCheck } from 'react-lazyload';
import getIconForCategoryKey from '../../utils/getIconForCategoryKey';
import getIconColorForCategoryKey from '../../utils/getIconColorForCategoryKey';
import TeaserGrid from '../Common/TeaserGrid';
import { ParagraphActivityTabsFragmentFragment } from '../../generated/types';
import renderTeaser from '../../utils/render-teaser';

interface TabTeaserGridProps {
  label?: string;
  subTitle?: string;
  tabs: ParagraphActivityTabsFragmentFragment['categoryItems'];
}

const Inner = ({ tab }: { tab: any }) => {
  return (
    <Box margin={-4}>
      <TeaserGrid>
        {tab.items.map((item: any) => renderTeaser(item!))}
      </TeaserGrid>
    </Box>
  );
};

const TabTeaserGrid: React.FC<TabTeaserGridProps> = ({
  label,
  subTitle,
  tabs,
}) => {
  const timeoutRef = useRef<NodeJS.Timeout>();
  const [tabIndex, setTabIndex] = useState(0);
  const isMobile = useIsMobile();

  const handleTabChange = (index: number) => {
    setTabIndex(index);
    timeoutRef.current = setTimeout(() => {
      forceCheck();
    }, 5);
  };

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      forceCheck();
    }, 5);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  return (
    <Flex alignItems="center" justifyContent="space-between" marginBottom={4}>
      <Tabs
        variant="unstyled"
        colorScheme=""
        index={tabIndex}
        onChange={handleTabChange}
      >
        <ParagraphTitle
          label={label || ''}
          sub={subTitle || ''}
          ActionComponent={
            tabs!.length > 1 ? (
              <Flex
                alignItems="center"
                mt={{
                  base: 4,
                  md: 0,
                }}
              >
                <TabList>
                  {tabs!.map((tab, i) => {
                    const Icon = getIconForCategoryKey(tab!.category!.key!);
                    const isActive = tabIndex === i;
                    const iconBg = getIconColorForCategoryKey(
                      tab!.category!.key!
                    );
                    const isFirst = i === 0;
                    const isLast = i === tabs!.length - 1;

                    const borderColor = isActive ? iconBg : 'gray.300';

                    return (
                      <Tooltip
                        key={tab!.category!.id}
                        aria-label={tab!.category!.name}
                        label={tab!.category!.name}
                      >
                        <Tab
                          borderWidth="1px"
                          borderTopLeftRadius={isFirst ? 'md' : 0}
                          borderTopRightRadius={isLast ? 'md' : 0}
                          borderBottomLeftRadius={isFirst ? 'md' : 0}
                          borderBottomRightRadius={isLast ? 'md' : 0}
                          borderColor={borderColor}
                          borderRightWidth={isLast ? '1px' : 0}
                          bg={isActive ? iconBg : 'transparent'}
                          color={isActive ? '#fff' : 'gray.600'}
                          _hover={{
                            color: '#fff',
                            bg: iconBg,
                            borderColor: 'transparent',
                          }}
                        >
                          {Icon && <Icon />}
                        </Tab>
                      </Tooltip>
                    );
                  })}
                </TabList>
              </Flex>
            ) : undefined
          }
        />

        {tabs!.length > 0 && (
          <TabPanels marginTop={4}>
            {tabs!.map((tab) => (
              <TabPanel key={`i_${tab.category.id}`}>
                <Inner tab={tab} />
              </TabPanel>
            ))}
          </TabPanels>
        )}
      </Tabs>
    </Flex>
  );
};

export default TabTeaserGrid;
