import { ParagraphActivityTabsFragmentFragment } from '../../../generated/types';
import Container from '../../Layout/Container';
import Wrapper from '../Wrapper';
import React from 'react';
import TabTeaserGrid from '../../Common/TabTeaserGrid';

interface ActivityTabsProps {
    data: ParagraphActivityTabsFragmentFragment;
}

const ActivityTabs: React.FC<ActivityTabsProps> = ({ data }) => {
    return (
        <Wrapper className="ParagraphActivityTabs">
            <Container>
                <TabTeaserGrid
                    label={data.title}
                    subTitle={data.subtitle || ''}
                    tabs={data.categoryItems || []}
                />
            </Container>
        </Wrapper>
    );
};

export default ActivityTabs;
